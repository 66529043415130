import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles/";
import { ContactModal } from "../components/contactModal";
import Layout from "../components/layout";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: "2rem",
    },
    paragraph: {
        margin: "1rem auto",
        [theme.breakpoints.up("lg")]: {
            maxWidth: "70vw",
        },
    },
    imageContainer: {
        textAlign: "center",
    },
    image: {
        width: "50%",
        margin: "auto",
        filter: "drop-shadow(0 0 10px #444)",
    },
}));

export default function About() {
    const classes = useStyles();
    return (
        <Layout>
            <Grid container alignItems="center" className={classes.container}>
                <Grid
                    container
                    item
                    sm={12}
                    md={6}
                    className={classes.imageContainer}
                >
                    <img
                        src="/amrish_no_bg.png"
                        alt="amrish"
                        className={classes.image}
                    />
                </Grid>
                <Grid container item sm={12} md={6}>
                    <Grid item className={classes.paragraph}>
                        <Typography variant="h3">About Me</Typography>
                    </Grid>
                    <Grid item className={classes.paragraph}>
                        <Typography variant="h4">My Background</Typography>
                        <Typography>
                            I completed my Masters in Economics back in the 90s.
                            Right after my education I dived into the world of
                            Sales & Marketing. Through the years I gained
                            experience and learnt the nuances of sales. From
                            understanding the customer's requirements to
                            addressing all their concerns, I enhanced my skill
                            set. I built strong relationships through out my
                            journey of more than 20 years.
                        </Typography>
                    </Grid>
                    <Grid item className={classes.paragraph}>
                        <Typography variant="h4">My Values</Typography>
                        <Typography>
                            I believe a person is only as strong as his values.
                            Values guide our beliefs, attitudes, and behavior.
                            The decisions we make are a reflection of our values
                            and beliefs, and they are always directed towards a
                            specific purpose.
                        </Typography>
                        <Typography>
                            The values that I hold high: <br />
                            &#8226; Integrity <br />
                            &#8226; Accountability <br />
                            &#8226; Trust <br />
                            &#8226; Growth <br />
                        </Typography>
                    </Grid>

                    <Grid item className={classes.paragraph}>
                        <Typography variant="h4">
                            Real Estate Experience
                        </Typography>
                        <Typography>
                            With more than 8 years of experience in the Ontario
                            Real Estate market, I developed my skills in
                            negotiation, communication, property closing and
                            business solutions integration. I make sure that the
                            client was well-versed in the process, from the
                            initial deposit sales transaction and the entire
                            closing process to finding that next ideal property.
                            I believe every new client is an opportunity to
                            build a new relationship that will last for years to
                            come and every repeat client is an opportunity to
                            reinforce that relationship.
                        </Typography>
                    </Grid>
                    <Grid item className={classes.paragraph}>
                        <Typography variant="h4">What to expect</Typography>
                        <Typography>
                            When working with me, you can expect a stress-free,
                            smooth experience throughout the process. I ensure
                            that all the minute details are taken care of to
                            guarantee no last minute troubles. To add to that -
                            I just love to talk. So just give me call !!
                        </Typography>
                    </Grid>
                    <ContactModal></ContactModal>
                </Grid>
            </Grid>
        </Layout>
    );
}
